
	.login-wrapper {
		min-width: 1200px;
		height: 100vh;
		background: #fff;
		display: flex;

		.login-banner {
			width: 25%;
            color: #f0f7ff;
            background-color: #409EFF;
			background-image: url(../../assets/images/login-bg.png);
			background-repeat: no-repeat;
			background-size: 100% auto;
			background-position: left bottom;
			position: relative;

			.banner-title {
				font-size: 36px;
				margin-top: 50px;
				font-weight: bold;
				position: absolute;
				top: 30%;
				width: 100%;
				text-align: center;
			}
		}

		.login-content {
			flex: 1;
			width: 1%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			.login-content-title {
				font-size: 24px;
				font-weight: 500;
				margin-bottom: 60px;
				color: #000;
			}

			.el-form-item__label {
				line-height: 1.5;
			}

			.demo-ruleForm {
				width: 416px;

				.el-button {
					width: 100%;

					span {
						font-size: 16px;
						font-weight: 700;
					}
				}
			}
		}
	}
